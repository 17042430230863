.Home img{
  max-height: 200px;
}

.name {
  color: #741AAC;
  font-size: 80px;
  line-height: 1em;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}

.button {
  background-color: #5E00BB;
  border-radius: 2em;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: white;
  font-size: 2em;
  line-height: 1em;
  font-style: italic;
  font-weight: lighter;
  cursor: pointer;
}

.button span {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.button span::after {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.button:hover span::after {
  transform: scaleX(1);
}