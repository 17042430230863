.Navbar {
    height: 5vh;
}

.links {
    z-index: 999;
    left: 0;
    top: 10vh;
    width: 100vw;
    font-size: 500%;
    font-weight: bold;
    opacity: 0;
    position: fixed;
}

.links a {
    color: white;
    text-decoration: none;
    transition: 0.4s;
    display: block;
}
.links a:hover, .links a:hover:after {
    transition: 0.5s;
    color: #398d08;
}

.active-page-link {
    color: #398d08;
}

.active-page-link:hover {
    cursor: pointer;
}

.hero.active .links {
    position: fixed;
    opacity: 1;
    transition-delay: 0.4s; /* Slightly delay the fade in of the links after the navbar button is clicked*/
    transition-duration: 0.5s; /* Links will take 0.5s to fade in */
    pointer-events: all; /* Re-enable navbar / links as they are visible and should be clickable */
}

.hero .links {
    transition-duration: 0.3s; /* Links will fade OUT faster than they fade in */
    pointer-events: none; /* When the navbar / links are hidden, make sure they can't be clicked */
}

.menu-button {
    position: fixed;
    top: 0.4em;
    right: 0.7em;
    color: white;
    z-index: 999999999;
    font-size: 3em;
    cursor: pointer;
}

.hero {
    position: fixed;
    background: linear-gradient(to bottom,#7a19f1,#332179);
    background-attachment: fixed;
    z-index: 999;
    top:0;
    border-radius: 0 0 50% 50%;
    left: -1vw;
    width: 102vw;
    transition: 1s;
}
.hero:before {
    content: " ";
    right: 0;
    top: 0;
    opacity: .2;
    left: 0;
    height: 100px;
    position: absolute;
    z-index: 1;
    background-position: center bottom;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNDQwIDMyMCc+PHBhdGggZmlsbD0nIzUwMDBjYScgZmlsbC1vcGFjaXR5PScwLjknIGQ9J00wLDY0TDYwLDU4LjdDMTIwLDUzLDI0MCw0MywzNjAsODBDNDgwLDExNyw2MDAsMjAzLDcyMCwyNTAuN0M4NDAsMjk5LDk2MCwzMDksMTA4MCwzMTQuN0MxMjAwLDMyMCwxMzIwLDMyMCwxMzgwLDMyMEwxNDQwLDMyMEwxNDQwLDBMMTM4MCwwQzEzMjAsMCwxMjAwLDAsMTA4MCwwQzk2MCwwLDg0MCwwLDcyMCwwQzYwMCwwLDQ4MCwwLDM2MCwwQzI0MCwwLDEyMCwwLDYwLDBMMCwwWic+PC9wYXRoPjwvc3ZnPg==);
}
.hero:after {
    content: " ";
    right: 0;
    bottom: 0;
    opacity: 1;
    left: 0;
    height: 230px;
    opacity: .1;
    position: absolute;
    z-index: 1;
    background-position: center top;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNDQwIDMyMCc+PHBhdGggZmlsbD0nI2ZmZmZmZicgZmlsbC1vcGFjaXR5PScwLjc1JyBkPSdNMCwzMkw2MCw1My4zQzEyMCw3NSwyNDAsMTE3LDM2MCwxNDRDNDgwLDE3MSw2MDAsMTgxLDcyMCwxNjBDODQwLDEzOSw5NjAsODUsMTA4MCw4MEMxMjAwLDc1LDEzMjAsMTE3LDEzODAsMTM4LjdMMTQ0MCwxNjBMMTQ0MCwzMjBMMTM4MCwzMjBDMTMyMCwzMjAsMTIwMCwzMjAsMTA4MCwzMjBDOTYwLDMyMCw4NDAsMzIwLDcyMCwzMjBDNjAwLDMyMCw0ODAsMzIwLDM2MCwzMjBDMjQwLDMyMCwxMjAsMzIwLDYwLDMyMEwwLDMyMFonPjwvcGF0aD48L3N2Zz4=);
    transition-delay: 0.5s;
}
.hero-inner {
    position: relative;
    background-attachment: fixed;
    padding: 60px 0;
    z-index: 5;
}
.hero-inner:after {
    content: " ";
    right: 0;
    bottom: -70px;
    opacity: .1;
    left: 0;
    height: 330px;
    position: absolute;
    z-index: 0;
    background-position: center top;
    background-size: cover;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNDQwIDMyMCc+PHBhdGggZmlsbD0nI2ZmZmZmZicgZmlsbC1vcGFjaXR5PScxJyBkPSdNMCwyMjRMNjAsMjAyLjdDMTIwLDE4MSwyNDAsMTM5LDM2MCwxMjhDNDgwLDExNyw2MDAsMTM5LDcyMCwxNDkuM0M4NDAsMTYwLDk2MCwxNjAsMTA4MCwxMzguN0MxMjAwLDExNywxMzIwLDc1LDEzODAsNTMuM0wxNDQwLDMyTDE0NDAsMzIwTDEzODAsMzIwQzEzMjAsMzIwLDEyMDAsMzIwLDEwODAsMzIwQzk2MCwzMjAsODQwLDMyMCw3MjAsMzIwQzYwMCwzMjAsNDgwLDMyMCwzNjAsMzIwQzI0MCwzMjAsMTIwLDMyMCw2MCwzMjBMMCwzMjBaJz48L3BhdGg+PC9zdmc+);
    transition: 1s;
    transition-delay: 0.5s;
}

.hero.active:after, .hero.active .hero-inner:after {
    opacity: 0;
    transition: initial;
    transition-delay: initial;
}

.hero-divider {
    position: relative;
    height:10vh;
    transition: 1s; /* It will take x seconds for the navbar banner to show/hide */
}

.hero.active {
    left: -15vw;
    width: 130vw;
}
.hero.active .hero-divider {
    height: 70vh;
    width: 110% !important;
}

@media (max-width: 575.98px) {
    .Navbar {
        position: relative;
    }
    .menu-button {
        position: fixed;
    }

    .hero-divider {
        height: 3vh;
    }

    .links {
        font-size: 300%;
        top: 15vh;
    }

    .hero.active .hero-divider {
        height: 60vh;
    }
    .hero-inner:after {
        display: none;
    }
}