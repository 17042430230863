.FullPageSpinner {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.PartialPageSpinner {
    height: 75vh;
    weight: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-lg {
    height: 10rem;
    width: 10rem;
    color: #5E00BB;
}

