.Logo {
    font-weight: bold;
    color: white;
    font-size: 5em;
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0.5em;
    z-index:99999;
}

@media (max-width: 575.98px) {
    .Logo {
        position: fixed;
    }
}