body {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace !important;
  margin-top: 20vh !important;
}
body.stop-transitions * {
  transition: none !important;
}

main > div:not(.FullPageSpinner) > div:last-child {
  min-height: 75vh;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

main {
  max-width: 2500px;
  margin: 0 auto 0 auto;
}

.grecaptcha-badge { opacity:0;}

a {
  text-decoration: none !important;
}