.portfolio-item-small {
    color: white;
    font-weight: bold;
}
.portfolio-item-small .title {
    font-size: 140%;
}
.portfolio-item-small .client {
    font-size: 110%;
}
.portfolio-item-small .thumbnail {
    cursor: pointer;
    height: 300px;
    width: 100%;
    max-width:500px;
    border-radius: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: 0.2s;
    margin: 0 auto 0 auto;
}

.portfolio-item-small .thumbnail:hover {
    transform: translate(5px, -5px);
    filter: drop-shadow(-10px 10px 20px black);
}

.portfolio-item-focus img{
    max-width: 100%;
}

.back {
    cursor: pointer;
}